import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchClients = createAsyncThunk(
  "clientsSlice/fetchClients",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axios(`/api/users${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"]
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export {
  fetchClients
}