const themeColor = {
  DARK: "dark",
  LIGHT: "light"
};

const defaultLanguage = "en";
const checkSupporterLangWithOutMainLang = ["ua", "ru"];

const candidateRoute = [
  "/login",
  "/registration"
];

const typeAccountRoutes  = {
  ACCOUNT: "/account",
  CRM: "/crm"
}

const supportedLanguages = {
  "en": {
    "url": "",
    "routePrefix": "",
    "regex": "\\/$|\\b",
    "description": "English"
  },
  "ua": {
    "url": "/ua",
    "routePrefix": "ua",
    "regex": "\\/ua\\b",
    "description": "Українська"
  },
  "ru": {
    "url": "/ru",
    "routePrefix": "ru",
    "regex": "\\/ru\\b",
    "description": "Русский"
  }
};

const helmetHtmlByLang = {
  "en": "en-US",
  "ua": "uk-UA",
  "ru": "ru-RU"
};

// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409
};

const requestStatus = {
  LOADING: "loading",
  RESOLVED: "resolved",
  REJECTED: "rejected"
};

const roles = {
  ADMIN: "ROLE_ADMIN",
  MANAGER: "ROLE_MANAGER",
  CLIENT: "ROLE_CLIENT",
  SYSTEM: "ROLE_SYSTEM",
  SEO: "ROLE_SEO"
};

const arrayOperation = {
  FIRST: 0
};

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+(\\.[a-z-])*(\\.[a-z]{2,})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~])[a-zA-Z\d!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,50}$/,
  CRM_URL: /\/crm\//
};

const minLength = 10;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const timeConsts = {
  DAY: 86400000,
  NORMALIZER: 1000
};

const logsLevels = {
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const pageSkeletonKeys = {
  HOME_PAGE: "/home/HomePage",
  LOGIN_PAGE: "/login/LoginPage",
  REGISTRATION_PAGE: "/registration/RegistrationPage",
  GOODS_PAGE: "/goods/GoodsPage"
};

export {
  defaultLanguage,
  supportedLanguages,
  helmetHtmlByLang,
  themeColor,
  responseStatus,
  requestStatus,
  roles,
  regex,
  arrayOperation,
  mercureTopicOrigin,
  mercureUrl,
  minLength,
  timeConsts,
  logsLevels,
  checkSupporterLangWithOutMainLang,
  candidateRoute,
  pageSkeletonKeys,
  typeAccountRoutes
};
