export default {
  bodyBackgroundColor: "#191919",
  containerBackgroundColor: "#111111",
  text: "#FFFFFF",
  mainColor: "#c8b19b",
  mainColorDarker: "#a99581",
  mainBorderColor: "#000000",
  neutralPrimary: "#7e7e7e",
  switchPosition: "calc(100% - 19px)",
  input: {
    labelColor: "#A9A9A9",
    textColor: "#FFFFFF",
    placeholderColor: "#525252",
    backgroundColor: "#191919",
    borderColor: "rgba(1, 1, 1, 0.25)",
    borderColorActive: "rgba(1, 1, 1, 1)",
    readOnlyTextColor: "rgba(30,35,40,0.5)",
  },
  select: {
    labelColor: "#A9A9A9",
    backgroundColor: "#191919",
    borderColor: "#111111",
    backgroundColorSelected: "#a99581",
  },
  datePicker: {
    labelColor: "#A9A9A9",
    backgroundColor: "#191919",
    borderColor: "#111111",
    navigationBackgroundColor: "#111111",
    currentDateTextColor: "#FFFFFF",
  },
  primaryColors: {
    first: "#c8b19b",
    second: "#a99581",
    third: "rgba(130, 160, 130, 0.15)"
  },
  neutralColors: {
    first: "#1E2328",
    second: "#47525E",
    third: "#919DA9",
    fourth: "#8C8C8C",
    fifth: "#A6A6A6",
    sixth: "#E8E8E8",
    seven: "#F3F5F5",
    eight: "rgba(71, 82, 94, 0.25)"
  },
  dangerColors: {
    first: "#FF4C41",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#5b4444"
  },
  successColors: {
    first: "#19BE6F",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#17584D"
  },
  warningColors: {
    first: "#FF9900",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#514a37"
  },
  infoColors: {
    first: "#1971C5",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#20536B"
  },
  skeleton: {
    baseColor: "#202020",
    highlightColor: "#444"
  }
};