import styled from "styled-components";

const size = {
  size: ''
}

const changeContainerSize = (size) => {
  switch (size) {
    case 'xl':
      return `
        max-width: 1920px
      `
    case 'sm':
      return `
        max-width: 1170px
      `
    default:
      return `
        max-width: 1470px
      `
  }
}

export const StyledMainWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContainer = styled("div", size)`
  width: 100%;
  ${({ inTheCenter }) => inTheCenter && "max-width: 1470px"};
  margin: 0 auto;
  padding: ${({ inTheCenter }) => inTheCenter ? "0 16px" : "16px 32px"};
  background-color: ${({theme}) => theme.containerBackgroundColor};
  border-left: 1px solid ${({theme}) => theme.mainBorderColor};
  border-right: 1px solid ${({theme}) => theme.mainBorderColor};
  overflow-x: hidden;
  position: relative;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    padding: ${({ inTheCenter }) => inTheCenter ? "0 16px" : "16px"};
  }
`;

export const StyledContentWrapper = styled.div`
  padding-top: 25px;
`;