import Select, { Option } from "rc-select";
import i18next from "i18next";


import { StyledLanguageSelect } from "./styledLanguageSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { checkSupporterLangWithOutMainLang, defaultLanguage, supportedLanguages } from "../../../utils/consts";

const LanguageSelect = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [actualLang, setActualLang] = useState(i18next.language);
  const actualLng = useRef(i18next.language);

  const regex = new RegExp(`^/(${Object.keys(supportedLanguages).join("|")})/`);
  const replacePathName = pathname.replace(regex, "/");

  const handleChangeLang = (value) => {
    setActualLang(value);
    actualLng.current = value;
    i18next.changeLanguage(value);

    const newPath = value === defaultLanguage ? pathname.replace(regex, "/") : `/${value}${replacePathName}`;

    if (window.location.search.length > 0) {
      navigate(newPath + window.location.search, { replace: true });
    } else {
      navigate(newPath, { replace: true });
    }
  };

  const getUrlLanguage = (value) => {
    return value.split("/").find((itemLNG => {
      if (checkSupporterLangWithOutMainLang.includes(itemLNG)) {
        return itemLNG;
      }
    })) || defaultLanguage;
  };

  useEffect(() => {
    const path = window.location.href.split(window.location.host)[1];

    if (actualLng.current !== getUrlLanguage(path)) {
      const newPath = actualLng.current === defaultLanguage
        ? pathname.replace(regex, "/")
        : `/${actualLng.current}${replacePathName}`;

      if (window.location.search.length > 0) {
        navigate(newPath + window.location.search, { replace: true });
      } else {
        navigate(newPath, { replace: true });
      }
    }

    if (actualLng.current === defaultLanguage && window.location.pathname.includes(`/${defaultLanguage}/`)) {
      const compareUrl = path.split(defaultLanguage)[1];

      navigate(compareUrl, { replace: true });
    }

    if (actualLng.current !== defaultLanguage &&
      !Object.keys(supportedLanguages).some(item => window.location.pathname.includes(`/${item}`))) {
      const compareUrl = "/" + actualLng.current + path;

      navigate(compareUrl, { replace: true });
    }
  }, [pathname]);

  return (
    <StyledLanguageSelect
      id="lang"
      className="lang-select-wrapper"
    >
      <Select
        className="custom-select lang-select"
        name="lang"
        value={actualLang ? actualLang.toUpperCase() : null}
        onChange={(value) => handleChangeLang(value)}
        getPopupContainer={() => document.getElementById("lang")}
        dropdownMatchSelectWidth={false}
        direction="rtl"
      >
        {Object.entries(supportedLanguages).map(([key, value]) => {
          return (
            <Option value={key} key={key} className={key === actualLang ? "active" : ""}>
              <div className="option-select-item lang-select__item">
                <div className="lang-select__pref">{key.toUpperCase()}</div>
                <div className="lang-select__text">{value.description}</div>
              </div>
            </Option>
          );
        })}
      </Select>
    </StyledLanguageSelect>
  );
};

export default LanguageSelect;