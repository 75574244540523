import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { useSelector } from "react-redux";
import SuspenseWrapper from "./SuspenseWrapper";
import { examinationRouters } from "../../utils/customFunc/routersFunc";

const RouterBlock = ({ isCrmPath }) => {
  const { authenticated } = useSelector(state => state.auth);

  const { routers } = examinationRouters(authenticated);

  return (
    <Routes>
      <Route
        path="/"
        element={<Layout routers={routers} authenticated={authenticated} isCrmPath={isCrmPath} />}
      >
        {routers.map((route, index) => (
          <Route
            key={index}
            path={`/:locale?${route.path}`}
            element={<SuspenseWrapper pageComponentPath={route.pathComponentPage} />}
          />
        ))}
      </Route>
      <Route
        path="*"
        element={<Navigate to="/page-not-found" replace={true} />}
      />
    </Routes>
  );
};

export default React.memo(RouterBlock);