import styled from "styled-components";

export const StyledCRMSidebar = styled.div`
  height: calc(100% - 70px);
  padding: 12px 0;
  transition: background-color .3s ease-in-out;

  @media screen and (max-width: 992px) {
    height: 100%;
    padding-top: 72px;
    background-color: #232323;
  }
`

export const StyledSeparator = styled.div`
  margin: 10px 15px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const StyledSidebarItem = styled.div`
  padding: 0 12px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .sidebar-link {
    color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;

    &__icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
    }

    &__title {
      padding-left: 12px;
      color: #FFFFFF;
    }

    &:hover {
      .sidebar-link__title {
        color: rgba(255, 255, 255, 0.75);
      }

      .sidebar-link__icon {
        opacity: 1;
      }
    }
  }

  .active {
    background: #323231;
    color: ${({ theme }) => theme.mainColor};

    .sidebar-link__icon {
      opacity: 1;
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  overflow: hidden;
`;

/*export const StyledBadgeNotification = styled.div`
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: ${({theme}) => theme.neutralColors.seven};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  background: ${({theme}) => theme.warningColors.first};
  border-radius: 10px;
  z-index: auto;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
`;*/