import { cabinetSidebar } from "../../../../rbac-consts";
import { typeAccountRoutes } from "../../../../utils/consts";

const sidebarAccountRoutes = [
  {
    title: "goods",
    path: `${typeAccountRoutes.ACCOUNT}/goods`,
    perform: cabinetSidebar.GOODS,
    icon: "rocket"
  },
  {
    title: "orders",
    path: `${typeAccountRoutes.ACCOUNT}/orders`,
    perform: cabinetSidebar.ORDERS,
    icon: "reverse"
  },
  {
    title: "verification",
    path: `${typeAccountRoutes.ACCOUNT}/verification`,
    perform: cabinetSidebar.VERIFICATION,
    icon: "files"
  },
  {
    title: "settings",
    path: `${typeAccountRoutes.ACCOUNT}/account`,
    perform: cabinetSidebar.PROFILE,
    icon: "settings"
  }
];

export default sidebarAccountRoutes;