import { createSlice } from "@reduxjs/toolkit";
import { getExpirationDate, isExpired } from "../../utils/checkExpiredToken";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { parseAxiosError } from "../../utils/response";
import { responseStatus } from "../../utils/consts";
import { authenticationRequest, handleRegistration } from "./asyncAuthSlice";

const initialState = {
  token: localStorage.getItem("token"),
  authenticated: localStorage.getItem("token") && !isExpired(getExpirationDate(localStorage.getItem("token"))),
  loading: false,
  userTwoFa: false,
  errors: null
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    handleLogout: (state) => {
      state.authenticated = false;
      localStorage.removeItem("clientId");
      localStorage.removeItem("token");
    },
    unsetAuthData: (state) => {
      state.userTwoFa = false;
    },
    setAuthErrors: (state, action) => {
      state.errors = action;
    },
    removeAuthErrors: (state, action) => {
      if (state.errors?.[action.payload]) {
        delete state.errors[action.payload];
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(authenticationRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authenticationRequest.fulfilled, (state, action) => {
      if ("token" in action.payload) {
        state.authenticated = true;
        localStorage.setItem("token", action.payload.token);
      }

      if ("userId" in action.payload) {
        state.userTwoFa = true;
      }

      state.loading = false;
    });
    builder.addCase(authenticationRequest.rejected, (state, action) => {
      closableNotification(action.payload === "The presented password is invalid."
        ? "Invalid password"
        : action.payload, "error");

      state.loading = false;
    });

    /*USER REGISTRATION*/
    builder.addCase(handleRegistration.pending, (state) => {
    });
    builder.addCase(handleRegistration.fulfilled, (state, action) => {

      authSlice.caseReducers.setAuthErrors(state, null);

      action.payload.callback();
    });
    builder.addCase(handleRegistration.rejected, (state, action) => {
      state.errors = parseAxiosError(action.payload);

      if (action.payload.status === responseStatus.HTTP_BAD_REQUEST) {
        const key = Object.keys(action.payload.data);
        state.errors[key] = Object.values(action.payload.data);
      }
    });
  }
});

export const { handleLogout, unsetAuthData, setAuthErrors, removeAuthErrors } = authSlice.actions;
export default authSlice.reducer;