import { Outlet } from "react-router-dom";
import { StyledLayoutWrapper, StyledUserLayoutWrapper } from "../styledLayout";
import { useState } from "react";
import HeaderCRM from "./header/HeaderCRM";
import SidebarCRMContainer from "./sidebar/SidebarCRMContainer";

const CRMLayoutPageWrapper = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCloseSideBar = () => {
    setCollapsed(false);
    setToggled(false);
  };

  return (
    <>
      <HeaderCRM
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        setToggled={setToggled}
        toggled={toggled}
      />
      <StyledLayoutWrapper changeWidth={toggled}>
        <SidebarCRMContainer collapsed={collapsed} toggled={toggled} toggleSidebar={handleCloseSideBar} />
        <StyledUserLayoutWrapper changeWidth={collapsed}>
          <Outlet />
        </StyledUserLayoutWrapper>
      </StyledLayoutWrapper>
    </>
  );
};

export default CRMLayoutPageWrapper;