import styled from "styled-components";
import { StyledHomeWrapper } from "../examples/styledExamples";

export const StyledSkeletonBlock = styled.div`
  .base-skeleton-wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }
`

export const StyledSkeletonHomeWrapper = styled(StyledHomeWrapper)`
  display: flex;
  justify-content: center;
`;