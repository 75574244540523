const routes = [
  {
    path: `/`,
    pathComponentPage:"/home/HomePage"
  },
  {
    path: `/page-not-found`,
    pathComponentPage: "/home/HomePage"
  },
  {
    path: `/login`,
    pathComponentPage: "/login/LoginPage"
  },
  {
    path: `/registration`,
    pathComponentPage: "/registration/RegistrationPage"
  },
  {
    path: `/example-components`,
    pathComponentPage: "/example/ExamplePage"
  },
  {
    path: `/email-confirm/:token?`,
    pathComponentPage: "/emailConfirm/EmailConfirmPage"
  },
  {
    path: `/remind`,
    pathComponentPage: "/forgotPassword/ForgotPasswordPage"
  },
  {
    path: `/change-password/:token?`,
    pathComponentPage: "/changePassword/ChangePasswordPage"
  }
];

export default routes;