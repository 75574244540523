import useRouterGuard from "../../utils/customHooks/useRouterGuard";
import AccountLayoutPageWrapper from "./account/AccountLayoutPageWrapper";
import CRMLayoutPageWrapper from "./crm/CRMLayoutPageWrapper";

const Layout = ({ routers, authenticated, isCrmPath }) => {

  useRouterGuard(authenticated, routers);

  return isCrmPath ? <CRMLayoutPageWrapper /> : <AccountLayoutPageWrapper />;
};

export default Layout;