import styled from "styled-components";
import { StyledSelect } from "../../styles/styledSelect";

export const StyledLanguageSelect = styled(StyledSelect)`
  width: 45px;

  input {
    display: none;
  }

  .active {
    background-color: ${({ theme }) => theme.primaryColors.first} !important;
    color: #FFFFFF !important;
  }

  .lang-select {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 1;
    line-height: inherit;
    transition: inherit;

    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }

    .lang-select__item {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }

    &__text {
      display: none;
    }

    &__pref {
      color: #ffffff;
    }

    .rc-select-selector {
      min-height: auto;
      padding: 0;
    }

    .rc-select-selection-item {
      padding: 0;
    }

    .rc-select-arrow {
      width: 15px;
      height: 15px;

      .rc-select-arrow-icon {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;

        &:before {
          width: 15px;
          height: 15px;
          color: #ffffff;
          font-size: 12px;
        }
      }
    }
  }

  .rc-select-dropdown {
    .rc-select-item-option {
      color: ${({ theme }) => theme.text};
      padding: 0;

      .lang-select__item {
        padding: 10px 15px;
      }

      .lang-select__text {
        display: block;
      }

      .lang-select__pref {
        display: none;
      }

      &:hover {
        color: #ffffff;
      }
    }

    .rc-select-item-option-selected {
      color: #ffffff;
    }
  }
`;