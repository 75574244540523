export default {
  bodyBackgroundColor: "#f9f8f6",
  containerBackgroundColor: "#FFFFFF",
  text: "#494949",
  mainColor: "#c8b19b",
  mainColorDarker: "#a99581",
  mainBorderColor: "#EFE6DD",
  neutralPrimary: "#afafaf",
  switchPosition: "1px",
  input: {
    labelColor: "#656565",
    textColor: "#656565",
    placeholderColor: "#919DA9",
    backgroundColor: "#FFFFFF",
    borderColor: "rgba(235, 235, 235, 0.25)",
    borderColorActive: "rgba(235, 235, 235, 1)",
    readOnlyTextColor: "rgba(30,35,40,0.5)",
  },
  select: {
    labelColor: "#656565",
    backgroundColor: "#FFFFFF",
    borderColor: "#E5E5E5",
    backgroundColorSelected: "#a99581",
  },
  datePicker: {
    labelColor: "#656565",
    backgroundColor: "#FFFFFF",
    borderColor: "#E5E5E5",
    navigationBackgroundColor: "#323233",
    currentDateTextColor: "#FFFFFF",
  },
  primaryColors: {
    first: "#c8b19b",
    second: "#a99581",
    third: "rgba(130, 160, 130, 0.15)"
  },
  neutralColors: {
    first: "#1E2328",
    second: "#47525E",
    third: "#919DA9",
    fourth: "#8C8C8C",
    fifth: "#A6A6A6",
    sixth: "#E8E8E8",
    seven: "#F3F5F5",
    eight: "rgba(71, 82, 94, 0.25)"
  },
  dangerColors: {
    first: "#FF4C41",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#FEDEDC"
  },
  successColors: {
    first: "#19BE6F",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#D1F2E2"
  },
  warningColors: {
    first: "#FF9900",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#FFEBCC"
  },
  infoColors: {
    first: "#1971C5",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#D9EDFF"
  },
  skeleton: {
    baseColor: "#dad9d9",
    highlightColor: "#cec9c9"
  }
};