import { StyledContainer } from "../../styles/styledContainer";
import Skeleton from "react-loading-skeleton";
import SkeletonMainBlock from "../SkeletonMainBlock";
import { StyledGoodsAddForm, StyledGoodsWrapper } from "../../goods/styledGoods";
import { StyledFilterHead, StyledFilterWrapper } from "../../styles/styledFilter";

const GoodsPageSkeleton = () => {

  return (
    <StyledContainer>
      <SkeletonMainBlock>
        <StyledGoodsWrapper>
          <Skeleton height={40} width={68} count={1} containerClassName="base-skeleton-wrapper" />
          <StyledFilterWrapper id="filter" pb="25">
            <StyledFilterHead>
              <Skeleton height={40} width={68} count={1} />
              <Skeleton height={40} width={68} count={1} />
            </StyledFilterHead>
          </StyledFilterWrapper>
          <StyledGoodsAddForm>
            <Skeleton height={40} count={1} containerClassName="base-skeleton-wrapper"/>
            <Skeleton height={40} count={1} containerClassName="base-skeleton-wrapper"/>

            <Skeleton height={40} width={68} count={1} containerClassName="base-skeleton-wrapper"/>
          </StyledGoodsAddForm>
          <Skeleton height={50} count={10} containerClassName="base-skeleton-wrapper" />
        </StyledGoodsWrapper>
      </SkeletonMainBlock>
    </StyledContainer>
  );
};

export default GoodsPageSkeleton;