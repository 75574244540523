import { pageSkeletonKeys } from "../../utils/consts";
import PageSpinner from "../../components/elements/spinner/PageSpinner";
import GoodsPageSkeleton from "../../components/skeletons/pages/GoobsPageSkeleton";

const suspenseFallbackFabric = (pageComponentPath) => {
  switch (pageComponentPath) {
    case pageSkeletonKeys.GOODS_PAGE: {
      return <GoodsPageSkeleton />;
    }
    default: {
      return <PageSpinner />;
    }
  }
}

export {
  suspenseFallbackFabric
}