import { crmSidebar } from "../../../../rbac-consts";
import { typeAccountRoutes } from "../../../../utils/consts";

const sidebarCRMRoutes = [
  {
    title: "dashboard",
    path: `${typeAccountRoutes.CRM}/dashboard`,
    perform: crmSidebar.DASHBOARD,
    icon: "market-place"
  },
  {
    title: "clients",
    path: `${typeAccountRoutes.CRM}/clients`,
    perform: crmSidebar.CLIENTS,
    icon: "users"
  },
  {
    title: "orders",
    path: `${typeAccountRoutes.CRM}/orders`,
    perform: crmSidebar.ORDERS,
    icon: "reverse"
  },
  {
    title: "widget",
    path: `${typeAccountRoutes.CRM}/widget`,
    perform: crmSidebar.WIDGET,
    icon: "web"
  },
  {
    title: "rates",
    path: `${typeAccountRoutes.CRM}/rates`,
    perform: crmSidebar.RATES,
    icon: "trend"
  },
  {
    title: "fees",
    path: `${typeAccountRoutes.CRM}/fees`,
    perform: crmSidebar.FEES,
    icon: "connection"
  },
  {
    title: "baseFees",
    path: `${typeAccountRoutes.CRM}/base-fees`,
    perform: crmSidebar.BASE_FEE,
    icon: "connection"
  },
  {
    title: "feeGroup",
    path: `${typeAccountRoutes.CRM}/fee-group`,
    perform: crmSidebar.FEE_GROUP,
    icon: "connection"
  },
  {
    title: "paymentSettings",
    path: `${typeAccountRoutes.CRM}/payment-settings`,
    perform: crmSidebar.PAYMENT_SETTINGS,
    icon: "crypto-settings"
  },
  {
    title: "verification",
    path: `${typeAccountRoutes.CRM}/verification`,
    perform: crmSidebar.VERIFICATION,
    icon: "files"
  },
  {
    title: "reports",
    path: `${typeAccountRoutes.CRM}/reports`,
    perform: crmSidebar.REPORTS,
    icon: "checklist"
  },
  {
    title: "stabling",
    path: `${typeAccountRoutes.CRM}/stabling`,
    perform: crmSidebar.STABLING,
    icon: "piggybank"
  },
  {
    title: "supportUsers",
    path: `${typeAccountRoutes.CRM}/support-users`,
    perform: crmSidebar.SUPPORT_USER,
    icon: "user-add"
  },
  {
    title: "logs",
    path: `${typeAccountRoutes.CRM}/logs`,
    perform: crmSidebar.LOGS,
    icon: "history"
  }
];

export default sidebarCRMRoutes;