import { createSlice } from "@reduxjs/toolkit";
import { fetchClients } from "./asyncClientsSlice";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";

const initialState = {
  clients: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: 10
  }
};

const clientsSlice = createSlice({
  name: "clientsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchClients.pending, () => {
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload.data;
      state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
      state.loading = false;
    });
    builder.addCase(fetchClients.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });
  }
});

export const { setPaginationInfo } = clientsSlice.actions;

export default clientsSlice.reducer;