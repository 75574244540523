import React, { lazy, Suspense } from "react";
import { suspenseFallbackFabric } from "./suspenseFallbackFabric";
const SuspenseWrapper = ({ pageComponentPath }) => {
  const LazyComponent = lazy(() => import((`../../pages${pageComponentPath}`)));

  return (
    <Suspense fallback={suspenseFallbackFabric(pageComponentPath)}>
      <LazyComponent />
    </Suspense>
  );
};

export default React.memo(SuspenseWrapper);
