import RouterBlock from "./routes/routerComponents/RouterBlock";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "./store/auth/authSlice";
import { removeUserInfo } from "./store/user/userSlice";
import { fetchThemeConfig } from "./store/theme/asyncThemeSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AutoLogoutModal from "./components/elements/modal/AutoLogoutModal";
import userAuthenticationConfig from "./utils/userAuthenticationConfig";

import axios from "axios";
import eventBus from "./utils/eventBus";

import "./i18n";
import i18n from "i18next";
import { defaultLanguage, helmetHtmlByLang, regex } from "./utils/consts";

import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "./components/styles/globalStyle";
import "./assets/css/main.min.css";
import "./assets/fonts/default-icons/style.css";
import { StyledMainWrapper } from "./components/styles/styledContainer";

function App() {
  const url = window.location.href;

  const isCrmPath = regex.CRM_URL.test(url);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const [lngFromUrl, setLngFromUrl] = useState(defaultLanguage);

  useEffect(() => {
    dispatch(fetchThemeConfig());

    i18n.on("languageChanged", (language) => {
      setLngFromUrl(language);
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  const isValidToken = () => {
    axios.post("/api/is-valid-token", {}, userAuthenticationConfig(true)).catch(() => {
      localStorage.removeItem("token");
      dispatch(handleLogout());
      dispatch(removeUserInfo());

      navigate("/login");
    });
  };

  const handleOnIdle = () => {
    eventBus.on("logout", (data) => {
      if (data?.expired) {
        setVisible(true);
      }

      dispatch(handleLogout());
      dispatch(removeUserInfo());

      navigate("/");
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      isValidToken();
    }
    handleOnIdle();
  }, []);

  const { themeMode, loading, siteConfig } = useSelector(state => state.theme);

  if (loading) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href={siteConfig.favicon} />
          <html lang={helmetHtmlByLang[lngFromUrl]} />
        </Helmet>
        <AutoLogoutModal
          visible={visible}
          setVisible={setVisible}
        />
        <StyledMainWrapper>
          <RouterBlock isCrmPath={isCrmPath} />
        </StyledMainWrapper>
        <GlobalStyle />
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
