import React from "react";
import SidebarCRMItem from "./SidebarCRMItem";
import CustomLink from "../../../elements/customLink/CustomLink";

import sidebarCRMRoutes from "./sidebarCRMRoutes";

const SidebarCRMLinkList = ({ t, role, toggleSidebar }) => {
  return (
    sidebarCRMRoutes.map((rout) => {
      return (
        <SidebarCRMItem
          key={rout.title}
          role={role}
          perform={rout.perform}
          as={CustomLink}
          to={rout.path}
          icon={rout.icon}
          title={t(rout.title)}
          handleChange={() => toggleSidebar(false)}
        />
      );
    })
  );
};

export default SidebarCRMLinkList;