import { typeAccountRoutes } from "../utils/consts";

const crmRoutes = [
  {
    path: `${typeAccountRoutes.CRM}/dashboard`,
    pathComponentPage: "/dashboard/DashboardPage"
  },
  {
    path: `${typeAccountRoutes.CRM}/clients`,
    pathComponentPage: "/clients/ClientsPage"
  },
  {
    path: `${typeAccountRoutes.CRM}/goods`,
    pathComponentPage: "/home/GoodsPage"
  }
];

export default crmRoutes;