import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchGoods = createAsyncThunk(
  "goodsSlice/fetchGoods",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axios(`/api/goods/${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"]
        }
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const addNewGoodsItem = createAsyncThunk(
  "goodsSlice/addNewGoodsItem",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/api/goods", data, userAuthenticationConfig());

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteGoodsItem = createAsyncThunk(
  "goodsSlice/deleteGoodsItem",
  async (id, thunkAPI) => {
    try {
      await axios.delete(`/api/goods/${id}`, userAuthenticationConfig());

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export {
  fetchGoods,
  addNewGoodsItem,
  deleteGoodsItem
}