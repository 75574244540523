import CustomLink from "../customLink/CustomLink";
import { useSelector } from "react-redux";

import { StyledLogoWrapper } from "./styledLogo";

const Logo = () => {
  const { siteConfig } = useSelector(store => store.theme);

  return (
    <CustomLink to="/">
      <StyledLogoWrapper>
        <img src={siteConfig?.logo} alt={`${siteConfig?.siteName}-logo`}/>
        <span>{siteConfig?.siteName}</span>
      </StyledLogoWrapper>
    </CustomLink>
  );
};

export default Logo;