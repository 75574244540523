import SidebarAccountItem from "./SidebarAccountItem";
import CustomLink from "../../../elements/customLink/CustomLink";

import sidebarAccountRoutes from "./sidebarAccountRoutes";

const SidebarAccountLinkList = ({ role, handleChange }) => {
  return (
    sidebarAccountRoutes.map((rout) => {
      return (
        <SidebarAccountItem
          key={rout.title}
          role={role}
          perform={rout.perform}
          as={CustomLink}
          to={rout.path}
          icon={rout.icon}
          title={rout.title}
          handleChange={handleChange}
        />
      );
    })
  );
};

export default SidebarAccountLinkList;