import { useTranslation } from "react-i18next";
import { Sidebar } from "react-pro-sidebar";
import Can from "../../../elements/can/Can";
import SidebarCRMLinkList from "./SidebarCRMLinkList";
import eventBus from "../../../../utils/eventBus";

import { StyledSeparator, StyledCRMSidebar, StyledSidebarItem, StyledSidebarLink } from "./styledCRMSidebar";
import CustomLink from "../../../elements/customLink/CustomLink";
import { settingAccess } from "../../../../rbac-consts";
import { useSelector } from "react-redux";
import { typeAccountRoutes } from "../../../../utils/consts";

const SidebarCRMContainer = ({ collapsed, toggled, toggleSidebar }) => {
  const { t } = useTranslation("sidebar", { useSuspense: false });

  const user = useSelector(store => store.user.user);

  return (
    <Sidebar className="sidebar-aside" collapsedWidth="68px" breakPoint="lg" collapsed={collapsed} toggled={toggled}>
      <StyledCRMSidebar>
        <SidebarCRMLinkList t={t} role={user.roles} toggleSidebar={toggleSidebar} />
        <StyledSeparator />
        <Can
          role={user.roles}
          perform={settingAccess.TRUE}
          yes={() => (
            <StyledSidebarItem>
              <StyledSidebarLink
                exact={true}
                as={CustomLink}
                to="/"
                className="sidebar-link"
              >
                <div className="sidebar-link__icon">
                  <span className="icon-home" />
                </div>
                <div className="sidebar-link__title">
                  {t("home")}
                </div>
              </StyledSidebarLink>
            </StyledSidebarItem>
          )}
          no={() => (
            <StyledSidebarItem>
              <StyledSidebarLink
                as={CustomLink}
                to={`${typeAccountRoutes.ACCOUNT}/goods`}
                className="sidebar-link"
              >
                <div className="sidebar-link__icon">
                  <span className="icon-premium" />
                </div>
                <div className="sidebar-link__title">
                  {t("cabinet")}
                </div>
              </StyledSidebarLink>
            </StyledSidebarItem>
          )}
        />
        <StyledSeparator />
        <StyledSidebarItem>
          <StyledSidebarLink
            as="button"
            className="sidebar-link"
            onClick={() => {
              eventBus.dispatch("logout");
            }}
          >
            <div className="sidebar-link__icon">
              <span className="icon-sign-out" />
            </div>
            <div className="sidebar-link__title">
              {t("logout")}
            </div>
          </StyledSidebarLink>
        </StyledSidebarItem>
      </StyledCRMSidebar>
    </Sidebar>
  );
};

export default SidebarCRMContainer;