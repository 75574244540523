import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchThemeConfig = createAsyncThunk(
  "themeSlice/fetchThemeConfig",
  async (_, thunkAPI) => {
    try {
      const host = window.location.host;
      const response = await axios.get(`/configs/${host}.config.json`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export {
  fetchThemeConfig
};