import Dialog from "rc-dialog";
import { useIdleTimer } from "react-idle-timer";
import eventBus from "../../../utils/eventBus";

import { StyledButton } from "../../styles/styledButton";

const AutoLogoutModal = ({ visible, setVisible }) => {

  const handleOnIdle = event => {
    if (localStorage.getItem("token")) {
      eventBus.dispatch("logout", { expired: true });
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    debounce: 500,
    onIdle: handleOnIdle
  });

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title="Attention"
      forceRender={false}
      className="default-modal"
    >
      <div className="default-modal__content">
        <p>
          For the safety and security of your account, an automatic logout from your account was performed.
          records.
        </p>
        <p>
          To continue working, please log in again.
        </p>
      </div>
      <div className="default-modal__footer">
        <StyledButton
          color="main"
          border
          onClick={() => setVisible(false)}
        >
          Ок
        </StyledButton>
      </div>
    </Dialog>
  );
};

export default AutoLogoutModal;