import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import themeSlice from "./theme/themeSlice";
import userSlice from "./user/userSlice";
import goodsSlice from "./goods/goodsSlice";
import clientsSlice from "./clients/clientsSlice";

export default configureStore({
  reducer: {
    goods: goodsSlice,
    auth: authSlice,
    theme: themeSlice,
    user: userSlice,
    clients: clientsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.callback', 'meta.arg.callback'],
      },
    }),
});