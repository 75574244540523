import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { candidateRoute, checkSupporterLangWithOutMainLang } from "../consts";
import { pathToRegexp } from "path-to-regexp";

const useRouterGuard = (authenticated, routers) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authenticated && candidateRoute.some(route => location.pathname.includes(route))) {
      navigate("/", { replace: true });
    }

    const isValidUrl = /^\/(en|ua|ru|)$/.test(location.pathname);

    const isValidPath = routers.some((route) => {
      if (isValidUrl && checkSupporterLangWithOutMainLang.some(item => location.pathname.includes(`/${item}`))) {
        navigate(`${location.pathname}/`, { replace: true });
        return true;
      }

      const keys = [];
      const regex = pathToRegexp(`/:locale(en|ua|ru)?${route.path}`, keys);
      const match = location.pathname.match(regex);

      if (!match) {
        return false;
      }

      // Тут ви можете використовувати keys, щоб отримати значення параметрів
      // keys містить об'єкти { name, prefix, delimiter, optional, repeat }
      return true;
    });

    if (!isValidPath) {
      navigate("/page-not-found", { replace: true });
    }
  }, [location.pathname]);


  return null;
};

export default useRouterGuard;