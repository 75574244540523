import routes from "./routes";
import crmRoutes from "./crmRoutes";
import { typeAccountRoutes } from "../utils/consts";

const accountRoutes = [
  {
    path: `${typeAccountRoutes.ACCOUNT}/goods`,
    pathComponentPage: "/goods/GoodsPage"
  },
  {
    path: `${typeAccountRoutes.ACCOUNT}/clients`,
    pathComponentPage: "/clients/ClientsPage"
  },
  {
    path: `${typeAccountRoutes.ACCOUNT}/account`,
    pathComponentPage: "/account/AccountSettingsPage"
  }
];

const accountRoutesConcat = accountRoutes.concat(routes, crmRoutes);

export default accountRoutesConcat;