import styled from "styled-components";
import { StyledContainer } from "../../../styles/styledContainer";

export const StyledHeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  padding: 15px 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media screen and (max-width: 992px) {
    height: 60px;
    padding: 10px 16px;
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  position: static;
  overflow-x: visible;

  .header-left,
  .header-right {
    max-width: 215px;
    width: 100%;
    display: inline-flex;
    gap: 4px;
    align-items: center;

    &__collapse,
    &__bar {
      width: 25px;
      height: 25px;
      font-size: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.75;
      transition: all .3s ease;

      &:hover {
        opacity: 1;
      }
    }

    &__bar {
      display: none;
    }
  }

  .header-right {
    justify-content: flex-end;
  }

  @media screen and (max-width: 992px) {
    .header-left,
    .header-right {
      max-width: 90px;

      &__collapse {
        display: none;
      }

      &__bar {
        padding-top: 3px;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .header-left {
      order: 2;
    }

    .header-right {
      order: 3;
    }
  }
`;