import styled from "styled-components";

export const StyledLogoWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transition: all .3s ease;
  img {
    width: 100%;
    height: 50px;
    object-position: center;
    object-fit: contain;
    transition: all .3s ease;
  }
  span {
    font-size: 28px;
    font-weight: 600;
  }
  @media screen and (max-width: 992px) {
    span {
      display: none;
    }
  }
`;