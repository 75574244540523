const cabinetSidebar = {
  PROFILE: "profile:cabinet-sidebar",
  GOODS: "goods:cabinet-sidebar",
  ORDERS: "order:cabinet-sidebar",
  VERIFICATION: "verification:cabinet-sidebar"
};

const crmSidebar = {
  DASHBOARD: "dashboard:crm-sidebar",
  CLIENTS: "clients:crm-sidebar",
  WIDGET: "widget:crm-sidebar",
  ORDERS: "order:crm-sidebar",
  VERIFICATION: "verification:crm-sidebar",
  RATES: "rates:crm-sidebar",
  FEES: "fees:crm-sidebar",
  BASE_FEE: "base-fee:crm-sidebar",
  FEE_GROUP: "fee-group:crm-sidebar",
  PAYMENT_SETTINGS: "payment-settings:crm-sidebar",
  REPORTS: "reports:crm-sidebar",
  STABLING: "stabling:crm-sidebar",
  SUPPORT_USER: "support-user:crm-sidebar",
  LOGS: "logs:crm-sidebar"
};

const clients = {
  ADMIN: "clients:admin",
  MANAGER: "clients:manager"
};

const goods = {
  ADMIN: "goods:admin",
  MANAGER: "goods:manager",
  CLIENT: "goods:client"
};

const dashboard = {
  ADMIN: "dashboard:admin",
  MANAGER: "dashboard:manager",
  CLIENT: "dashboard:client"
};

const settingAccess = {
  TRUE: "setting-access:true"
};

const account = {
  CLIENT: "account:client",
};

export {
  cabinetSidebar,
  crmSidebar,
  settingAccess,
  clients,
  goods,
  account,
  dashboard
};