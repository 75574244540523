import { createSlice } from "@reduxjs/toolkit";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { addNewGoodsItem, deleteGoodsItem, fetchGoods } from "./asyncGoodsSlice";

const initialState = {
  goods: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: 10
  }
};

const goodsSlice = createSlice({
  name: "goodsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    }
  },
  extraReducers: builder => {
    /*GET GOODS DATA*/
    builder.addCase(fetchGoods.pending, (state) => {
    });
    builder.addCase(fetchGoods.fulfilled, (state, action) => {
      state.goods = action.payload.data;
      state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
      state.loading = false;
    });
    builder.addCase(fetchGoods.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });

    /*ADD NEW GOODS*/
    builder.addCase(addNewGoodsItem.pending, (state) => {
    });
    builder.addCase(addNewGoodsItem.fulfilled, (state, action) => {
      closableNotification("Item was added", "success");
      state.goods.unshift(action.payload);
      state.loading = false;
    });
    builder.addCase(addNewGoodsItem.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });

    /*DELETE GOODS*/
    builder.addCase(deleteGoodsItem.pending, (state) => {
    });
    builder.addCase(deleteGoodsItem.fulfilled, (state, action) => {
      closableNotification("Item was added", "success");
      state.goods = state.goods.filter(item => item.id !== action.payload);
      state.loading = false;
    });
    builder.addCase(deleteGoodsItem.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });
  }
});

export const { setPaginationInfo } = goodsSlice.actions;
export default goodsSlice.reducer;