import Logo from "../../../elements/logo/Logo";
import { StyledHeaderContainer, StyledHeaderWrapper } from "./styledHeaderCRM";
import LanguageSelect from "../../../elements/language/LanguageSelect";

const HeaderCRM = ({ setCollapsed, collapsed, setToggled, toggled }) => {
  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer>
        <div className="header-left">
          <Logo to="/crm/dashboard" />
          <div className="header-left__collapse" onClick={() => setCollapsed(prev => !prev)}>
            <span className={collapsed ? "icon-chevron-right" : "icon-chevron-left"} />
          </div>
        </div>
        <div className="header-right">
          <LanguageSelect />
          <div className="header-right__bar" onClick={() => setToggled(prev => !prev)}>
            <span className={toggled ? "icon-cancel" : "icon-bar"} />
          </div>
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default HeaderCRM;