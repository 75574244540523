import { useTranslation } from "react-i18next";

import { StyledSidebarItem, StyledSidebarLink } from "./styledAccountSidebar";
import Can from "../../../elements/can/Can";

const SidebarAccountItem = ({ role, perform, as, to, icon, title, handleChange, exact, badge }) => {
  const { t } = useTranslation("sidebar");

  return (
    <Can
      role={role}
      perform={perform}
      yes={() => (
        <StyledSidebarItem badge={badge}>
          <StyledSidebarLink
            exact={exact}
            as={as}
            to={to}
            onClick={handleChange}
            className="sidebar-link"
          >
            <div className="sidebar-link__icon">
              <span className={`icon-${icon}`} />
            </div>
            <div className="sidebar-link__title">
              {t(title)}
            </div>
          </StyledSidebarLink>
        </StyledSidebarItem>
      )}
    />
  );
};

export default SidebarAccountItem;