import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const handleForgotPassword = createAsyncThunk(
  "userSlice/handleForgotPassword",
  async (userEmail, thunkAPI) => {
    try {
      const response = await axios.post("/api/users/forgot-password", { email: userEmail });

      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const handleCheckTwoFaIsActive = createAsyncThunk(
  "userSlice/checkTwoFaIsActive",
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axios(`/api/users/authenticator-secret-is-active/${userId}`, userAuthenticationConfig());

      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.detail);
    }
  }
);

const handleGetAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleGetAuthenticatorSecretUser",
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axios(`/api/users/authenticator-secret/${userId}`, userAuthenticationConfig());

      return {
        data: response.data
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const handleSetAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleSetAuthenticatorSecretUser",
  async ({ data, callback }, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axios.put(`/api/users/authenticator-secret/${userId}`, data, userAuthenticationConfig());

      return {
        status: response.status,
        callback
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const handleRemoveAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleRemoveAuthenticatorSecretUser",
  async ({data, callback}, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axios.put(`/api/users/authenticator-secret-remove/${userId}`, data, userAuthenticationConfig());

      return {
        status: response.status,
        callback
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const handleChangePassword = createAsyncThunk(
  "userSlice/handleChangePassword",
  async ({ data, callback }, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axios.put(`/api/users/change-password/${userId}`, data, userAuthenticationConfig());

      return {
        data: response.data,
        callback
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export {
  handleForgotPassword,
  handleCheckTwoFaIsActive,
  handleGetAuthenticatorSecretUser,
  handleSetAuthenticatorSecretUser,
  handleRemoveAuthenticatorSecretUser,
  handleChangePassword
};