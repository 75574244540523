import styled from "styled-components";

export const StyledGoodsWrapper = styled.div`
  padding-top: 50px;
`;

export const StyledGoodsAddForm = styled.div`
  padding: 16px;
  margin-bottom: 26px;
  background-color: ${({ theme }) => theme.bodyBackgroundColor};
  border: 1px solid ${({ theme }) => theme.mainBorderColor};

  button {
    gap: 8px;
  }
`;

export const StyledGoodsActions  = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;