import styled from "styled-components";


export const StyledSeparator = styled.div`
  margin: 10px 15px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const StyledAuthButtons = styled.div`
  margin-right: 25px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 25px;
  .login-button {
    outline: none;
    &__icon {
      display: none;
    }
  }
  @media screen and (max-width: 992px) {
    margin-right: 10px;
    grid-gap: 0;
    .login-button {
      padding: 0;
      border: none;
      background-color: transparent;
      &__icon {
        height: 100%;
        width: 30px;
        padding: 0;
        margin: 0;
        color: #FFFFFF;
        display: flex;
        font-size: 22px;
        align-items: center;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          color: ${({ theme }) => theme.mainColor};
        }
      }
      &__text {
        display: none;
      }
    }
    .registration-button {
      display: none;
    }
  }
`;

export const StyledSidebarWrapper = styled.div`
  .sidebar-separator {
    margin: 10px 0;
    height: 1px;
    display: block;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const StyledSidebarAction = styled.div`
  margin-right: 10px;
  display: grid;
  grid-template-columns: max-content;
  grid-gap: 20px;
  button {
    width: 30px;
    padding: 0;
    margin: 0;
    color: #FFFFFF;
    font-size: 22px;
    align-items: center;
    border:  none;
    outline: none;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }
  }
`;

export const StyledAccountSidebar = styled.div`
  margin-top: 12px;
`

export const StyledSidebarItem = styled.div`
  padding: 0 12px;
  cursor: pointer;
  position: relative;

  .sidebar-link {
    color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;

    &__icon {
      width: 20px;
      height: 20px;
      font-size: 16px;
      text-align: center;
    }

    &__title {
      padding-left: 12px;
      color: #FFFFFF;
    }

    &:hover {
      transition: all .3s ease;

      .sidebar-link__title {
        color: rgba(255, 255, 255, 0.75);
      }

      .sidebar-link__icon {
        opacity: 1;
      }
    }
  }

  .active {
    background: #232323;
    color: ${({ theme }) => theme.mainColor};

    .sidebar-link__icon {
      opacity: 1;
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
`;
