import styled from "styled-components";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (color, border, theme) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? theme.primaryColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.primaryColors.first};
        border: 1px solid ${theme.primaryColors.first};
        &:hover {
          background-color: ${border ? theme.primaryColors.third : theme.primaryColors.second};
          border-color: ${border ? theme.primaryColors.first : theme.primaryColors.third};
        }
     `;
    case "success":
      return `
        color: ${border ? theme.successColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.successColors.first};
        border: 1px solid ${theme.successColors.first};
        &:hover {
          background-color: ${border ? theme.successColors.third : theme.successColors.second};
          border-color: ${border ? theme.successColors.first : theme.successColors.second};
        }
     `;
    case "danger":
      return `
        color: ${border ? theme.dangerColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.dangerColors.first};
        border: 1px solid ${theme.dangerColors.first};
        &:hover {
          background-color: ${border ? theme.dangerColors.third : theme.dangerColors.second};
          border-color: ${border ? theme.dangerColors.first : theme.dangerColors.second};
        }
      `;
    case "warning":
      return `
        color: ${border ? theme.warningColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.warningColors.first};
        border: 1px solid ${theme.warningColors.first};
         &:hover {
          background-color: ${border ? theme.warningColors.third : theme.warningColors.second};
          border-color: ${border ? theme.warningColors.first : theme.warningColors.second};
        }
     `;
    case "info":
      return `
        color: ${border ? theme.infoColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.infoColors.first};
        border: 1px solid ${theme.infoColors.first};
         &:hover {
          background-color: ${border ? theme.infoColors.third : theme.infoColors.second};
          border-color: ${border ? theme.infoColors.first : theme.infoColors.second};
        }
     `;
    default:
      return `
        color: ${border ? theme.neutralColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.neutralColors.second};
        border: 1px solid ${theme.neutralColors.second};
         &:hover {
          background-color: ${border ? theme.neutralColors.eight : theme.neutralColors.second};
          border-color: ${border ? theme.neutralColors.first : theme.neutralColors.second};
        }
     `;
      ;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ width }) => width && `width: ${width}%`};
  padding: ${({ figure }) => figure === "circle" ? "12px" : "8px 16px"};
  font-size: 14px;
  font-weight: ${({ weight }) => weight || "500"};
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "5px"};
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};

  ${({ color, border, theme }) => styleButton(color, border, theme)}
  &:active {
    transform: scale(0.95) translateZ(0);
  }
`;