import { createSlice } from "@reduxjs/toolkit";
import { defaultSiteConfig } from "../../utils/defaultSideConfig";
import { themeColor } from "../../utils/consts";
import { darkTheme, lightTheme } from "../../components/styles/theme/theme";
import { fetchThemeConfig } from "./asyncThemeSlice";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";

const colorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

const examinationDefaultTheme = colorScheme ? themeColor.DARK : themeColor.LIGHT;

const setThemeModeByDefault = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme") === themeColor.LIGHT ? lightTheme : darkTheme;
  }

  return colorScheme ? darkTheme : lightTheme;
};

const initialState = {
  theme: localStorage.getItem("theme") || examinationDefaultTheme,
  themeMode: setThemeModeByDefault(),
  error: null,
  loading: true,
  siteConfig: defaultSiteConfig
};

const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    handleChangeTheme: (state, action) => {
      const examinationCurrentTheme = state.theme === themeColor.LIGHT;
      state.theme = examinationCurrentTheme ? themeColor.DARK : themeColor.LIGHT;

      state.themeMode = examinationCurrentTheme ? state.darkTheme : state.lightTheme;

      localStorage.setItem("theme", state.theme);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchThemeConfig.pending, () => {
    });
    builder.addCase(fetchThemeConfig.fulfilled, (state, action) => {
      state.siteConfig = {
        ...defaultSiteConfig,
        ...action.payload
      };
      state.lightTheme = {
        ...lightTheme,
        ...action.payload.lightTheme
      };
      state.darkTheme = {
        ...darkTheme,
        ...action.payload.darkTheme
      };
      state.themeMode = state.theme === themeColor.LIGHT ? state.lightTheme : state.darkTheme;
      state.loading = false;
    });
    builder.addCase(fetchThemeConfig.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });
  }
});

export const { handleChangeTheme } = themeSlice.actions;

export default themeSlice.reducer;