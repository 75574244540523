import routes from "../../routes/routes";
import accountRoutesConcat from "../../routes/accountRoutes";

const examinationRouters = (authenticated) => {

  if (!authenticated) {
    return {
      routers: routes
    };
  }

  return {
    routers: accountRoutesConcat
  };
};

export {
  examinationRouters
};