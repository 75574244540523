import { account, cabinetSidebar, clients, crmSidebar, dashboard, goods, settingAccess } from "./rbac-consts";

const rules = {
  ROLE_CLIENT: {
    static: [
      //navbar rules
      cabinetSidebar.GOODS,
      cabinetSidebar.PROFILE,
      cabinetSidebar.ORDERS,
      cabinetSidebar.VERIFICATION,
      crmSidebar.DASHBOARD,
      crmSidebar.WIDGET,
      crmSidebar.RATES,
      crmSidebar.FEES,
      crmSidebar.REPORTS,
      crmSidebar.STABLING,
      //other
      dashboard.CLIENT,
      goods.CLIENT,
      account.CLIENT
    ],
    dynamic: {}
  },
  ROLE_ADMIN: {
    static: [
      //navbar rules
      crmSidebar.DASHBOARD,
      crmSidebar.CLIENTS,
      crmSidebar.ORDERS,
      crmSidebar.WIDGET,
      crmSidebar.RATES,
      crmSidebar.BASE_FEE,
      crmSidebar.FEE_GROUP,
      crmSidebar.PAYMENT_SETTINGS,
      crmSidebar.VERIFICATION,
      crmSidebar.REPORTS,
      crmSidebar.STABLING,
      crmSidebar.SUPPORT_USER,
      crmSidebar.LOGS,
      //other
      settingAccess.TRUE,
      dashboard.ADMIN,
      clients.ADMIN,
      goods.ADMIN
    ],
    dynamic: {}
  },
  ROLE_MANAGER: {
    static: [
      //navbar rules
      crmSidebar.DASHBOARD,
      crmSidebar.CLIENTS,
      crmSidebar.ORDERS,
      crmSidebar.WIDGET,
      //other
      settingAccess.TRUE,
      dashboard.MANAGER,
      clients.MANAGER,
      goods.MANAGER
    ],
    dynamic: {}
  }
};

export default rules;
