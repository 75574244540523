import { Outlet } from "react-router-dom";
import { StyledLayoutWrapper } from "../styledLayout";
import Header from "./header/Header";

const AccountLayoutPageWrapper = () => {
  return (
    <>
      <Header />
      <StyledLayoutWrapper>
        <Outlet />
      </StyledLayoutWrapper>
    </>
  );
};

export default AccountLayoutPageWrapper;