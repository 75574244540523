import getUserInfo from "../../utils/getUserInfo";
import { createSlice } from "@reduxjs/toolkit";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import {
  handleChangePassword,
  handleCheckTwoFaIsActive,
  handleForgotPassword,
  handleGetAuthenticatorSecretUser,
  handleRemoveAuthenticatorSecretUser,
  handleSetAuthenticatorSecretUser
} from "./asyncUserSlice";
import { parseAxiosError } from "../../utils/response";
import { responseStatus } from "../../utils/consts";

const initialState = {
  loading: false,
  user: localStorage.getItem("token") ? getUserInfo() : null,
  twoFaIsActive: false,
  authenticatorSecret: false,
  errors: null
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserInfo: (state) => {
      state.user = getUserInfo();
    },
    removeUserInfo: (state) => {
      state.user = null;
    },
    setUserErrors: (state, action) => {
      state.errors = action;
    }
  },
  extraReducers: builder => {
    /*HANDLE FORGOT PASSWORD*/
    builder.addCase(handleForgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleForgotPassword.fulfilled, (state, action) => {
      closableNotification(action.payload.data.success, "success");
      state.loading = false;
    });
    builder.addCase(handleForgotPassword.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });

    /*CHECK TWO FA IS ACTIVE*/
    builder.addCase(handleCheckTwoFaIsActive.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleCheckTwoFaIsActive.fulfilled, (state, action) => {
      state.twoFaIsActive = action.payload.data.isActive;
      state.loading = false;
    });
    builder.addCase(handleCheckTwoFaIsActive.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });

    /*GET AUNTHETICATOR SECRET USER*/
    builder.addCase(handleGetAuthenticatorSecretUser.pending, (state) => {
    });
    builder.addCase(handleGetAuthenticatorSecretUser.fulfilled, (state, action) => {
      state.authenticatorSecret = action.payload.data;
    });
    builder.addCase(handleGetAuthenticatorSecretUser.rejected, (state, action) => {
      closableNotification(action.payload, "error");
    });

    /*SET AUNTHETICATOR SECRET USER*/
    builder.addCase(handleSetAuthenticatorSecretUser.pending, (state) => {
    });
    builder.addCase(handleSetAuthenticatorSecretUser.fulfilled, (state, action) => {
      state.twoFaIsActive = true;
      action.payload.callback();
    });
    builder.addCase(handleSetAuthenticatorSecretUser.rejected, (state, action) => {
      closableNotification(action.payload, "error");
    });

    /*REMOVE AUNTHETICATOR SECRET USER*/
    builder.addCase(handleRemoveAuthenticatorSecretUser.pending, (state) => {
    });
    builder.addCase(handleRemoveAuthenticatorSecretUser.fulfilled, (state, action) => {
      state.twoFaIsActive = false;
      action.payload.callback();
    });
    builder.addCase(handleRemoveAuthenticatorSecretUser.rejected, (state, action) => {
      closableNotification(action.payload, "error");
    });

    /*CHANGE USER PASSWORD*/
    builder.addCase(handleChangePassword.pending, (state) => {
    });
    builder.addCase(handleChangePassword.fulfilled, (state, action) => {
      userSlice.caseReducers.setUserErrors(state, null);

      action.payload.callback();

      closableNotification(action.payload.data.success, "success");
    });
    builder.addCase(handleChangePassword.rejected, (state, action) => {
      state.errors = parseAxiosError(action.payload);

      if (action.payload.status === responseStatus.HTTP_BAD_REQUEST) {
        const key = Object.keys(action.payload.data);
        state.errors[key] = Object.values(action.payload.data);
      }
    });
  }
});

export const { setUserInfo, removeUserInfo } = userSlice.actions;
export default userSlice.reducer;