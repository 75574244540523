import { Trans } from "react-i18next";
import Can from "../../../elements/can/Can";

import { StyledSidebarItem, StyledSidebarLink } from "./styledCRMSidebar";

const SidebarCRMItem = ({ role, perform, as, to, icon, title, handleChange, exact, badge }) => {
  return (
    <Can
      role={role}
      perform={perform}
      yes={() => (
        <StyledSidebarItem badge={badge}>
          <StyledSidebarLink
            exact={exact}
            as={as}
            to={to}
            onClick={handleChange}
            className="sidebar-link"
          >
            <div className="sidebar-link__icon">
              <span className={`icon-${icon}`} />
            </div>
            <div className="sidebar-link__title">
              <Trans components={{ br: <br /> }}>
                {title}
              </Trans>
            </div>
          </StyledSidebarLink>
        </StyledSidebarItem>
      )}
    />
  );
};

export default SidebarCRMItem;