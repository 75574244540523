import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const authenticationRequest = createAsyncThunk(
  "authSlice/authenticationRequest",
  async (authData, thunkAPI) => {
    try {
      const response = await axios.post("/api", authData.body, authData.headers);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.detail);
    }
  }
);

const handleRegistration = createAsyncThunk(
  "authSlice/handleRegistration",
  async ({ data: registerData, callback }, thunkAPI) => {

    try {
      const response = await axios.post("/api/users", registerData);

      return {
        data: response.data,
        callback
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export {
  authenticationRequest,
  handleRegistration
};