import { css } from "@emotion/react";
import { MoonLoader } from "react-spinners";

import { useSelector } from "react-redux";

const Spinner = ({ size, color, display, margin }) => {

  const themeMode = useSelector(store => store.theme.themeMode);

  const defaultColor = themeMode.mainColor;
  const override = css`
    display: ${display || "block"};
    margin: ${margin || "15px auto"};
  `;

  return (
    <MoonLoader css={override} size={size} color={color ?? defaultColor} loading={true} />
  );
};

export default Spinner;