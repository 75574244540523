import Logo from "../../../elements/logo/Logo";
import SidebarAccountContainer from "../sidebar/SidebarAccountContainer";

import { StyledHeaderContainer, StyledHeaderWrapper } from "./styledHeader";
import Navigation from "../../../elements/navigation/Navigation";
import LanguageSelect from "../../../elements/language/LanguageSelect";

const Header = () => {
  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer wrapper="content">
        <div className="header-left">
          <Logo />
        </div>
        <Navigation />
        <div className="header-right">
          <SidebarAccountContainer />
          <LanguageSelect />
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default Header;