import { useState } from "react";
import Drawer from "rc-drawer";
import SidebarAccountLinkList from "./SidebarAccountLinkList";
import { useTranslation } from "react-i18next";

import eventBus from "../../../../utils/eventBus";

import { StyledButton } from "../../../styles/styledButton";
import {
  StyledAuthButtons, StyledSeparator,
  StyledAccountSidebar,
  StyledSidebarAction,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarWrapper
} from "./styledAccountSidebar";
import { useSelector } from "react-redux";
import CustomLink from "../../../elements/customLink/CustomLink";

const SidebarAccountContainer = () => {
  const { t, ready } = useTranslation("sidebar", { useSuspense: false });

  const user = useSelector(store => store.user.user);

  const [visible, setVisible] = useState(false);

  const showSidebar = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!ready) {
    return null;
  }

  if (!user) {
    return (
      <StyledAuthButtons>
        <StyledButton
          color="main"
          border="true"
          as={CustomLink}
          to="/login"
          className="login-button"
        >
          <span className="login-button__icon icon-account" />
          <span className="login-button__text">{t("login")}</span>
        </StyledButton>
        <StyledButton
          color="main"
          as={CustomLink}
          to="/registration"
          className="registration-button"
        >
          {t("registration")}
        </StyledButton>
      </StyledAuthButtons>
    );
  }

  return (
    <StyledSidebarWrapper>
      <StyledSidebarAction>
        <button
          title="Profile"
          onClick={showSidebar}
        >
          <span className="icon-account" />
        </button>
      </StyledSidebarAction>
      <Drawer
        width="320"
        placement="right"
        handler={false}
        open={visible}
        onClose={onClose}
        forceRender
      >
        <StyledAccountSidebar>
          <SidebarAccountLinkList
            handleChange={onClose}
            role={user.roles}
          />

          <StyledSidebarItem>
            <StyledSidebarLink
              as={CustomLink}
              to="/crm/dashboard"
              className="sidebar-link"
            >
              <div className="sidebar-link__icon">
                <span className="icon-briefcase" />
              </div>
              <div className="sidebar-link__title">
                {t("crm")}
              </div>
            </StyledSidebarLink>
          </StyledSidebarItem>

          <StyledSeparator />
          <StyledSidebarItem>
            <StyledSidebarLink
              as="button"
              className="sidebar-link"
              onClick={() => {
                onClose();
                eventBus.dispatch("logout");
              }}
            >
              <div className="sidebar-link__icon">
                <span className="icon-sign-out" />
              </div>
              <div className="sidebar-link__title">
                {t("logout")}
              </div>
            </StyledSidebarLink>
          </StyledSidebarItem>
        </StyledAccountSidebar>
      </Drawer>
    </StyledSidebarWrapper>
  );
};

export default SidebarAccountContainer;